import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import PostList from '../components/Posts/PostList';
import { decodeEntities } from '../utils/helpers';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { allWordpressPost, categories, wordpressWpSettings, siteSettings } = data;
    const { showAuthor } = siteSettings.options
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressPost
    const featuredImage = categories.edges[0] && categories.edges[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
    return (
      <Layout location={location}>
        <SEO title={`Blog | ${decodeEntities(siteTitle)}`} />
        <PageHeader headerTitle="Blog" location={location} headerBackgroundImage={featuredImage} />
        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
          showAuthor={showAuthor}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
