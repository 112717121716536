import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink'
import './PostListNavigation.scss'

export const ListNavigation = props => {
    const { data, pathPrefix } = props;
    const { allWordpressCategory } = data;
    const { nodes: categories } = allWordpressCategory
    return (
      <div className="post-list-navigation">
        <div className="inner">
          <strong>Filter by: </strong>
          <ul>
            <li><GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">All</GatsbyLink></li>
            {categories && categories.map((category , index) => category.slug !== 'uncategorised' && (
              <li key={index}><GatsbyLink to={category.path} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/></li>
            ))}
          </ul>
        </div>
      </div>
    )
}


export const PostListNavigation = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressCategory {
            nodes {
              name
              slug
              path
              id
            }
          }
        }
      `}
      render={data => <ListNavigation {...props} data={data} />}
    />
  );
}
